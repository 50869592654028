<template>
	<div class="tsa-header">
		<a href='#'
			v-tooltip.bottom="{content:'Home'}"
			@click.prevent="goHome" class="full-logo">
			<img src='@/assets/images/TSANet-Connect-Logo_registered_trademark.png' alt="TSANet Connect Logo" />
		</a>
		<a href='#' @click.prevent="goHome" class="icon-logo"
			v-tooltip.bottom="{content:'Home'}">
			<img src='@/assets/images/tsa-connect-logo-mobile.jpg' alt="TSANet Connect Logo" />
		</a>
		
		<!-- caller and admin nav -->
		<div class='tsa-nav' v-if="!isSuperAdmin && isLoggedIn">
			<a href="" @click.prevent="goHome">Home</a>
			<a href="" @click.prevent="toggleAdminMenu" v-tooltip.bottom="{content:'Menu'}" v-if="isAdmin">Admin</a>
			<a href="https://www.tsanet.org/news-events" target="_blank" >Events</a>
			<a :href="'https://www.tsanet.org/' + trainingLink()" target="_blank" >Training</a>
			<!-- profile -->
			<a href="#" @click.prevent="navigate({name: 'profile'})">Profile</a>
			<!-- logout mobile -->
			<a href="#" @click.prevent="logout">Logout</a>
		</div>
		<!-- super admin nav -->
		<div class='tsa-nav' v-if="isSuperAdmin && isLoggedIn">
			<a href="#" @click.prevent="navigate({name:'super_admin.list_users'})">Inactive Users</a>
			<a href="#" @click.prevent="navigate({name:'super_admin.companies'})">Companies</a>
			<a href="#" @click.prevent="navigate({name:'super_admin.groups'})">Groups</a>
			<a href="#" @click.prevent="navigate({name:'super_admin.reports'})">Reports</a>
			<a href="#" @click.prevent="navigate({name: 'profile'})">Profile</a>
			<a href="#" @click.prevent="logout">Logout</a>
		</div>
		<div class="company-info">
			<a v-if="isLoggedIn" :href="'https://www.tsanet.org/' + membershipLink()" class="tsa-btn tsa-btn-dark uk-margin-small-right hide-narrow" target="_blank">
				{{ company.membership_level_text }}
			</a>
			<company-logo :company-url="company.url" :company-name="company.name" />	
		</div>
		
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import CompanyLogo from "./CompanyLogo.vue";
	export default {
		name: 'tsa-header',
		components: {
			CompanyLogo
		},
		methods: {
			showAlerts(){

			},
			viewProfile(){
				this.$router.push({name:"profile"});
			},
			trainingLink(){
				if(!this.company){
					return "";
				}
				switch(this.company.membership_type){
					case "basic":
					case "standard":
					case "premium":
					case "elite":
						return "member-resources";
					case "limited":
					default:
						return "limited-training";
				}
				
			},
			membershipLink(){
				if(!this.company){
					return "";
				}
				switch(this.company.membership_type){
					case "basic":
					case "standard":
						return "standard-membership";
					case "premium":
						return "premium-membership";
					case "elite":
						return "elite-membership";
					case "limited":
					default:
						return "limited-membership";
				}
				
			},
			logout(){
				this.emitter.emit('logout');
			},
			goHome(){
				if(this.isLoggedIn){
					if(this.user.role == "super_admin"){
						this.$router.push({name: 'super_admin.dashboard'});
					}else{
						this.$router.push({name: 'dashboard'});
					}
				}else{
					let redirect = process.env.VUE_APP_COMPANY_URL.replace('{slug}',this.app_slug);
					window.location.href = redirect;	
				}
			},
			/**
			 * send request to toggle menu in store.
			 * @return {[type]} [description]
			 */
			toggleAdminMenu(){
				if(this.isAdmin){
					this.$store.dispatch('toggleAdminMenu');
				}
			},
			navigate(route){
				this.$router.push(route);
			}
		},
		computed:{
			/**
			 * show company logo only if available
			 * @return {[type]} [description]
			 */
			showCompanyLogo(){
				//return false;
				if(this.company == undefined) return false;
				return (this.company.logo != undefined && this.company.logo != null);
			},
			/**
			 * api url to load company logo
			 * @return {[type]} [description]
			 */
			companyLogoUrl(){
				return `${process.env.VUE_APP_API_URL}resources/company_logo/${this.company.slug}`;
			},
			isAdmin(){
				return (this.user.role == "program_manager" || this.user.role == "business_manager");
			},
			isSuperAdmin(){
				if(this.user == undefined) return false;
				return(this.user.role == 'super_admin');
			},
			isLoggedIn(){
				return (this.user.id != undefined);
			},
			...mapState({
				user: state => state.user,
				company: state => state.company,
				login_as_user: state => state.login_as_user,
				app_slug: state => state.app_slug,
			})
		}
	}
</script>
