<template>
	<vue-tel-input
		v-model="v_phone"
		:defaultCountry="country_code"
		@on-input="onPhoneChange"
		@country-changed="countryChanged"
		@keypress="onKeyPress"
		:placeholder="placeholder"
		:input-options="{
			autocomplete:'on',
			autofocus:false,
			'aria-describedby':ariaId,
			id:fieldId ? fieldId:autoId,
			maxlength:25,
			name:'telephone',
			placeholder:placeholder,
			readonly:false,
			required:false,
			tabindex:0,
			type:'tel',
			styleClasses:''
		}"
		mode="international"
		:key="componentKey" />
</template>

<script>
/*
{}
* */
	export default {
		props : {
			placeholder : {
				type : String,
				default : "Enter Phone"
			},
			defaultCountry : {
				type : String,
				default : "US"
			},
			phone: {
				type : String,
				default : ""
			},
			formatted: {
				type : Boolean,
				default : false
			},
			fieldId:{
				type:[Number,String],
				default: null
			},
			ariaId:{
				type:[Number,String],
				default: null
			}
		},
		name : 'telephone-field',
		data(){
			return {
				v_phone : "",
				country_code : "US",
				only_numbers : "",
				value_overwrite: false,
				componentKey: 1,
				is_valid: false
			}
		},
		mounted() {
			// if the country code is not the same as the default
			if((this.defaultCountry != undefined && this.defaultCountry != null) && this.defaultCountry != this.country_code){
				this.handleCountryCode(this.defaultCountry);
			}
			if(this.phone != undefined && this.phone != null){
				this.v_phone = this.phone;
				this.componentKey += 1;
			}
		},
		methods : {
			/**
			 * if country code is different rekey component
			 * @param  {[type]} new_country_code [description]
			 * @return {[type]}                  [description]
			 */
			handleCountryCode(new_country_code){
				if(this.country_code != new_country_code){
					this.country_code = new_country_code;
					setTimeout(() => {this.componentKey += 1},0);
				}
			},
			/**
			 * when input changes make sure letters cannot be entered
			 * remove dial code if it is in place.
			 * @param  {[type]} options.number  [description]
			 * @param  {[type]} options.isValid [description]
			 * @param  {[type]} options.country [description]
			 * @return {[type]}                 [description]
			 */
			onPhoneChange(number,event_data){
				if(event_data.country_code != undefined){
					this.handleCountryCode(event_data.countryCode);
				}
				if(event_data.valid || event_data.valid == undefined){
					this.v_phone = event_data.formatted;
				}else{
					this.v_phone = event_data.nationalNumber;
				}
				const dialCode = event_data.countryCallingCode == undefined ? "" : event_data.countryCallingCode;
				
				// make sure is only numbers
				setTimeout(() => this.onlyNumbers(number,dialCode),0);
				setTimeout(() => this.$emit('is-valid',event_data.valid),0);
			},
			/**
			 * strip all digits except numbers
			 * @param  {[type]} number   [description]
			 * @param  {[type]} dialCode [description]
			 * @return {[type]}          [description]
			 */
			onlyNumbers(number,dialCode){
				let numbers = number.match(/\d+/g);
				let onlyNumbers = (numbers != null)? numbers.join("") : "";
				// check if dial code is in
				if(onlyNumbers.indexOf(dialCode) === 0) onlyNumbers = onlyNumbers.substring(dialCode.length);
				this.only_numbers = onlyNumbers;
			},
			/**
			 * if the phone is updated by the user entering a number instead
			 * of the value being updated through a prop don't re-key component
			 * @return {[type]} [description]
			 */
			onKeyPress(){
				if(!this.value_overwrite) this.value_overwrite = true;
			},
			/**
			 * country changed on phone field.
			 * @param  {[type]} data [description]
			 * @return {[type]}      [description]
			 */
			countryChanged(data){
				this.country_code = data.iso2;
			},
		},
		watch: {
			/**
			 * send value to parent component
			 * @return {[type]} [description]
			 */
			only_numbers(){
				// if parent component wants phone formatted
				if(this.formatted){
					this.$emit('phone-changed',this.v_phone);
					this.$emit('only-numbers',this.only_numbers);
				}else{
					// send only digits (does not include country code)
					this.$emit('phone-changed',this.only_numbers);
				}
			},
			/**
			 * country code has changed so send it to the parent component.
			 * @return {[type]} [description]
			 */
			country_code(){
				this.$emit('country-changed', this.country_code)
			},
			defaultCountry(value){
				this.handleCountryCode(value);
			},
			/**
			 * if phone prop changes force component to re-render.
			 * the reason for this is the country code does not change
			 * must be a bug in the vue 3 beta version.
			 * @param  {[type]} val [description]
			 * @return {[type]}     [description]
			 */
			phone(val){

				if(!this.value_overwrite){
					this.v_phone = val;
					this.value_overwrite = true;
					this.componentKey += 1;
				}
			}
		},
		computed:{
			autoId(){
				let length = 7;
				let result = '';
				let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				let charactersLength = characters.length;
				for ( let i = 0; i < length; i++ ) {
					result += characters.charAt(Math.floor(Math.random() *
						charactersLength));
				}
				return result;
			}
		}
	}
</script>