<template>
	<div class="tsa-footer">
		<div class="uk-text-left">
			<a class="logo-credit" href="https://logo.dev" target="_blank" alt="Logo API">
				Logos provided by Logo.dev
			</a>
		</div>
		
		<div>
			<a href="https://www.tsanet.org/wp-content/uploads/2019/02/TSANet-Connect_V6.mp4" target="_blank">How it Works</a>
			<a href="https://www.tsanet.org/tsanet-security-data-protection/" target="_blank">Security</a>
			<a href="https://www.tsanet.org/legal-policy/" target="_blank">Privacy Policy</a>
			<a href="http://www.tsanet.org" target="_blank">TSANet.org</a>
			<a href="https://www.tsanet.org/tsanet-connect-releases/" class="tsa-btn" target="_blank">What's new</a>
		</div>
		<div>
			&nbsp;
		</div>
	</div>
</template>
<script>
	export default {
		name: 'tsa-footer',
		components:{

		},
		methods: {
			logoLink(val) {
				val = val.toString().replace("http://", "");
				val = val.toString().replace("https://", "");
				let parts = val.split("/");
				val = parts[0];

				return "https://img.logo.dev/" + val + `?token=${process.env.VUE_APP_LOGODEV}&size=60&format=png`;
			},
		}
	}
</script>
<style>
</style>