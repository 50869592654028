<template>
	<div class="tsa-box" style="height: 100%">
		<div class='tsa-heading'>Need An Account?</div>
		<div class="tsa-bottom-content uk-child-width-1-2@m uk-grid-small tsa-login-form" uk-grid>
			<div>
				<div class="form-row" :class="errorClass">
					<input type="email"
						v-model="email"
						@keyup.enter="onSendEmail"
						ref="field"
						placeholder="Enter your work email address" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
			</div>
			<div>
				<div class="domain">@{{ company.domain }}</div>
			</div>
			<div class="form-row tsa-note" :class="formRowClass('agree_confirm')" style="margin-top: 20px;width: 100%;">
				<label><input type="checkbox" v-model="agree_confirm" />
				I agree to the <a href="https://www.tsanet.org/legal-policy/" target="_blank">TSANet Privacy Policy</a></label>
				<span class="error-message">You must accept the terms of the privacy policy.</span>
			</div>
			<div>
				<button @click="onSendEmail" class="tsa-btn pull-right">Send Email</button>
			</div>
		</div>
	</div>
</template>

<script>

import {mapState} from 'vuex';

export default {
	name: 'email-login',
	data(){
		return {
			email: "",
			agree_confirm: false,
			errors:{}
		}
	},
	components: {
	},
	methods:{
		/**
		 * user wants to send a login link
		 * @return {[type]} [description]
		 */
		onSendEmail(){
			this.$refs.field.blur();
			// eslint-disable-next-line
			var data = {
				'email': `${this.email}@${this.company.domain}`,
				'company_id': this.company.id,
				'agree_confirm': this.agree_confirm
			};
			// show loader.
			this.emitter.emit('loader',true);
			// eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}caller/link`,data)
				.then(response => this.handleSendEmail(response.data))
				.catch(error => this.handleErrors(error.response.data.errors));
		},
		handleErrors (errors){
			// hide loader
			this.emitter.emit('loader',false);
			// clear all errors
			this.errors = {};
			for(var e in errors){
				this.errors[e] = errors[e][0];
			}
		},
		/**
		 * let user know they have to check their meail
		 * @param  {[type]} response [description]
		 * @return {[type]}          [description]
		 */
		handleSendEmail(response){
			// hide loader
			this.emitter.emit('loader',false);
			// let user know to check email.
			this.emitter.emit('alert',response.message);
		},
		formRowClass(field){
			let string = "form-row";
			if(this.errors[field] != undefined ) string += " has-error";
			return string;
		},
	},
	computed:{
		/**
		 * if email has error return has-error
		 * @return {[type]} [description]
		 */
		errorClass(){
			return (this.errors.email != undefined)? "has-error" : "";
		},
		...mapState({
			company: state => state.company,
		})
	}
}
</script>