<template>
    <div :class="containerClass">
        <div class="form-row tsa-custom-select" :class="{'has-error' : error.length > 0}">
            <select v-model="select_value" :placeholder="placeholder" :aria-label="emptyValue" :disabled="fieldDisabled">
                <option value="">{{ emptyValue }}</option>
                <option v-for="(option) in select_options" :key="option.id" :value="option.id">{{option.label}}</option>
            </select>
            <span class="error-block" v-if="select_value != 'other'"><strong>{{ error }}</strong></span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "TSANetDbSelect",
        props: {
            requirement: {
				type: Object,
				default: () => {}
			},
            error: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            },
			value: {
				type: Object,
				default: () => {}
			},
            placeholder: {
                type: String,
                default: "Choose One"
            },
            form_data: {
                type: Object,
                default: () => {

                }
            }
        },
        data(){
            return {
				select_value: "",
                foreign_key: "",
                select_options: [],
                field_disabled: false
			}
        },
        mounted(){
            this.loadOptions();
        },
        methods: {
            loadOptions(){
                //eslint-disable-next-line
                axios.post(`${process.env.VUE_APP_API_URL}caller/db_select_options`,{
                    model: this.requirement.requirement.options,
                    foreign_key: this.foreign_key
                })
                    .then(response => this.handleGetOptionsResponse(response.data))
                    //eslint-disable-next-line
                    .catch(error => console.log(error));
            },
            handleGetOptionsResponse(response){
				this.select_options = [];
				for(let i in response){
					this.select_options.push({
						id: i,
						label: response[i]
					});
				}
                this.select_options.sort(function(a, b) {
					var textA = a.label.toUpperCase();
					var textB = b.label.toUpperCase();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            }
        },
        computed: {
            emptyValue(){
                //eslint-disable-next-line
                let query = AvsAn.query(this.requirement.requirement.label);
                return `Choose ${query.article} ${this.requirement.requirement.label}`;
            },
            containerClass(){
                let string = `form-group-${this.requirement.group}`;
                if(this.requirement.group != null){
                    if(this.document_active_groups[this.requirement.group] === false){
                        string += " hide";
                    }
                }
                return string;
            },
            fieldDisabled(){
                if(this.disabled) return true;
                return this.field_disabled == true;
            },
            ...mapState({
                document_active_groups: state => state.document_active_groups
            })
        },
        watch:{
            /**
             * only send select option if not other
             * @return {[type]} [description]
             */
            select_value(){
                this.$emit('change',{
                    'requirement_id' : this.requirement.requirement_id,
                    'data' : this.select_value
                });
			},
            form_data(){
                // check for foreign_key
                const foreign_key = this.requirement.requirement.additional_settings;
                if(foreign_key == null) return;
                
                if(foreign_key.length){
                    // not the same
                    if(this.form_data[`custom_${foreign_key}`] != this.foreign_key){
                        // make it the same.
                        this.foreign_key = this.form_data[`custom_${foreign_key}`];
                    }
                }
            },
            foreign_key(){
                this.select_value = "";
                // empty
                if(this.foreign_key == ""){
                    this.select_options = [];
                    this.field_disabled = true;
                }else{  

                    this.field_disabled = false;
                    this.loadOptions();
                }
            }
		},
    }
</script>