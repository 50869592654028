<template>
    <div :class="containerClass">
        <div class="form-row tsa-custom-select" :class="{'has-error' : error.length > 0}">
            <select v-model="select_value" :placeholder="placeholder" :aria-label="placeholder" :disabled="disabled">
                <option value="">{{ emptyValue }}</option>
                <option v-for="(option,index) in select_options" :key="index" :value="option">{{option}}</option>
                <option value="other" v-if="additional_options.allow_other">Other</option>
            </select>
            <span class="error-block" v-if="select_value != 'other'"><strong>{{ error }}</strong></span>
        </div>
        <div
            v-if="this.select_value == 'other'"
            class="form-row tsa-custom-text"
            style="margin-left: 20px;"
            :class="{'has-error' : error.length > 0}">
            <input
                type="text"
                ref='textfield'
                :disabled="disabled"
                :placeholder="`Enter Other ${requirement.requirement.label}`"
                v-model="text_value" />
                <span class="error-block"><strong>{{ error }}</strong></span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "TSANetSelect",
        props: {
            requirement: {
				type: Object,
				default: () => {}
			},
            error: {
                type: String,
                default: ""
            },
			value: {
				type: Object,
				default: () => {}
			},
            placeholder: {
                type: String,
                default: "Choose One"
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
				select_value: "",
                text_value: "",
                select_options: [],
                additional_options : {
                    allow_other : true
                }
			}
        },
        mounted(){
            this.handleOptions();
        },
        methods: {
            handleOptions(){
                if(Array.isArray(this.requirement.options)){
                    this.select_options = this.requirement.options;
                }else{
                    this.select_options = this.requirement.options.split("\n");
                }
                const additionalOptions = JSON.parse(this.requirement.additional_settings);
                if(additionalOptions) this.additional_options.allow_other = additionalOptions.allow_other;
            },
        },
        computed: {
            emptyValue(){
                //eslint-disable-next-line
                let query = AvsAn.query(this.requirement.requirement.label);
                return `Choose ${query.article} ${this.requirement.requirement.label}`;
            },
            containerClass(){
                let string = `form-group-${this.requirement.group}`;
                if(this.requirement.group != null){
                    if(this.document_active_groups[this.requirement.group] === false){
                        string += " hide";
                    }
                }
                return string;
            },
            ...mapState({
                document_active_groups: state => state.document_active_groups
            })
        },
        watch:{
            /**
             * only send select option if not other
             * @return {[type]} [description]
             */
            select_value(){
                if(this.select_value != "other"){
                    this.text_value = "";
                }
                this.$emit('change',{
                    'requirement_id' : this.requirement.requirement_id,
                    'data' : this.select_value
                });
			},
            /**
             * if the textfield changed and they are using the other field.
             * @return {[type]} [description]
             */
            text_value(){

                if(this.select_value == "other"){
                    // sent value
                    this.$emit('change',{
                        'requirement_id' : this.requirement.requirement_id,
                        'data' : this.text_value
                    });
                }
            }
		}
    }
</script>