<template>
	<div :class="containerClass">
		<input
			type="text"
			ref='textfield'
			:placeholder="placeholder"
			:aria-label="placeholder"
			:v-model="value"
			:disabled="disabled"
			@input="emitChange" />
		<span class="error-block"><strong>{{ error }}</strong></span>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
    export default {
        name: "TSANetNumber",
        props: {
            requirement: {
				type: Object,
				default: () => {}
			},
			value: {
				type: String,
				default: ""
			},
            error: {
                type: String,
                default: ""
            },
            disabled: {
				type: Boolean,
				default: false
            }
        },
        data(){
            return {}
        },
        computed: {
			placeholder(){
				let ph = `Enter ${this.requirement.requirement.label}`;
				return ph;
			},
			containerClass(){
                let string = `form-group-${this.requirement.group} form-row tsa-custom-number`;
                if(this.error.length > 0) string += ' has-error';
                if(this.requirement.group != null){
                    if(this.document_active_groups[this.requirement.group] === false){
                        string += " hide";
                    }
                }
                return string;
            },
            ...mapState({
                document_active_groups: state => state.document_active_groups
            })
        },
		methods: {
			emitChange(){
				this.$emit('change',{
					requirement_id : this.requirement.requirement_id,
					data: this.$refs.textfield.value
				});
			},
        }
    }
</script>