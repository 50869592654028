<template>
	<div class="cases-list">
		
		<DataTable
			ref="casesTable"
			:utc_note="true"
			:table_data="my_cases"
			:table_headers="my_cases_headers"
			:page-data="pageData"
			:per_page="perPage"
			@page="gotoPage"
			@sort="sortBy"
		>
			<template v-slot:search>
				<select v-model="requestType" class="inline-input" aria-label="Case Request Type">
					<option value="mine-30">My Active Cases (30 days)</option>
					<option value="company-inbound-action">Company Inbound Action Needed</option>
					<option value="company-30">Company Active Cases (30 days)</option>
					<option value="mine-all">My Cases (all)</option>
					<option value="company-all">Company Cases (all)</option>
				</select>
			</template>
		</DataTable>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import DataTable from '@/components/Table/DataTable';
	import GlobalStorage from "../../Classes/GlobalStorage";
export default {
	name: 'my-cases',
	components: {
		DataTable
	},
	data(){
		return {
			gsPrefix: "ac_",
			gsFields:{
				requestType: "request_type",
				filterType: "filter_type",
				sortField: "sort_field",
				sortDirection: "sort_direction",
			},
			my_cases: [],
			has_initialized: false,
			loadingUpdate: false,
			initialPageLoaded: false,
			currentPage: 1,
			pageData: null,
			requestType:"mine-30",
			filterType:null,
			perPage: 10,
			sortField: "updated_at",
			sortDirection: "desc",
			defaultSortDirection: "desc",
			my_cases_headers:{
				internal_case_number: {
					display: "Case#",
					route: "/admin/case/{token}",
					sortable: false
				},
				direction:{
					display: "Type",
				},
				'partner.name': {
					display: "Partner",
					sortable: false
				},
				partner_case_number: {
					display: "Partner Case#",
					sortable: false
				},
				priority: {
					display: "Priority",
					render: cell_data => {
						return cell_data.priority.replace("_"," ").ucfirst();
					}
				},
				status: {
					display: "Status",
					render: cell_data => {
						return cell_data.status.replace("_"," ").ucfirst();
					}
				},
				updated_at: {
					display: "Last Update",
					format: "date_time",
					default_sort: 'desc'
				}
			}
		}
	},
	mounted(){
		this.loadMemory();
		this.initialize();
	},
	updated(){
		if(!this.has_initialized) this.initialize();
	},
	/**
	* remove bus event listeners before component is destroyed.
	* @return {[type]} [description]
	*/
	beforeUnmount(){
		this.emitter.off('token_ready');
	},
	methods:{
		loadMemory(){
			if(GlobalStorage.has(this.gsPrefix + this.gsFields.requestType)){
				this.requestType = GlobalStorage.load(this.gsPrefix + this.gsFields.requestType);
			}
			/*if(GlobalStorage.has(this.gsPrefix + this.gsFields.filterType)){
				this.filterType = GlobalStorage.load(this.gsPrefix + this.gsFields.filterType);
			}*/
			if(GlobalStorage.has(this.gsPrefix + this.gsFields.sortField)){
				this.sortField = GlobalStorage.load(this.gsPrefix + this.gsFields.sortField);
				if(GlobalStorage.has(this.gsPrefix + this.gsFields.sortDirection)){
					this.sortDirection = GlobalStorage.load(this.gsPrefix + this.gsFields.sortDirection);
					this.$refs.casesTable.setSortDisplay(this.sortField, this.sortDirection);
				} else {
					this.$refs.casesTable.setSortDisplay(this.sortField);
				}
			}
		},
		initialize(){
			this.has_initialized = true;
			this.emitter.on('token_ready', () => {
				if(!this.loadingUpdate && !this.initialPageLoaded){
					this.loadMyCases();
				}
			});
			if(this.jwt_token.access_token != undefined) this.loadMyCases();
		},
		
		/**
		 * send request to get user cases.
		 * created by user
		 * company needs to respond on.
		 * @return {[type]} [description]
		 */
		loadMyCases(){
			this.emitter.emit('loader',true);
			this.loadingUpdate = true;
			let type = "mine";
			let days = "30";
			let notClosed = true;
			this.filterType = null;
			switch (this.requestType){
				case "mine-30":
					type = "mine";
					days = "30";
					break;
				case "company-inbound-action":
					type = "company";
					days = null;
					this.filterType = "inbound-action";
					break;
				case "company-30":
					type = "company";
					days = "30";
					break;
				case "mine-all":
					type = "mine";
					days = null;
					notClosed = false;
					break;
				case "company-all":
					type = "company";
					days = null;
					notClosed = false;
					break;
			}
			let url = `${process.env.VUE_APP_API_URL}caller/get-cases/${type}`;
			let query = {};
			if(this.searchTerm){
				query.q = this.searchTerm;
			}
			if(this.sortField) {
				query.sort = this.sortField;
				GlobalStorage.save(this.gsPrefix + this.gsFields.sortField,this.sortField);
			}
			if(this.sortDirection) {
				query.dir = this.sortDirection;
				GlobalStorage.save(this.gsPrefix + this.gsFields.sortDirection,this.sortDirection);
			}
			if(this.filterType) {
				query.filter = this.filterType;
			}
			query.limit = this.perPage;
			query.page = this.currentPage;
			if(notClosed) query.not_closed = true;
			let qString = new URLSearchParams(query).toString();
			if(days != null){
				url += `/${days}`;
			}
			//eslint-disable-next-line
			axios.get(url + '?' + qString)
			.then(response => this.handleGetCases(response.data))
			//eslint-disable-next-line
			.catch(error => console.log(error));
		},	
		handleGetCases(response){
			this.loadingUpdate = false;
			this.initialPageLoaded = true;
			this.emitter.emit('loader',false);
			this.my_cases = response.data;
			this.pageData = response;
			this.perPage = response.per_page;
		},
		gotoPage(page){
			this.currentPage = page;
			this.loadMyCases();
		},
		sortBy(sorting){
			this.sortField = sorting.current;
			this.sortDirection = sorting.direction;
			this.currentPage = 1;
			this.loadMyCases();
		}
	},
	computed:{
		isAdmin(){
			return this.user.role == "business_manager" || this.user.role == "program_manager";
		},
		...mapState({
			jwt_token : state => state.token,
			user : state => state.user
		})
		
	},
	watch:{
		requestType(val){
			GlobalStorage.save(this.gsPrefix + this.gsFields.requestType,val);
			this.loadMyCases();
		},
		/*filterType(val){
			GlobalStorage.save(this.gsPrefix + this.gsFields.filterType,val);
			this.loadMyCases();
		}*/
	}
}
</script>