<template>
	<div>
		<div class="tsa-document-form uk-margin-medium-top">
			<div class="form-row radio">
				<label><input
					type="radio"
					name="case_update"
					checked="true"
					value="accepted"
					@click="optionChosen"
				/>
				Accept Collaboration Request</label>
			</div>
			<div v-if="case_entry.status === 'open'" class="form-row checkbox">
				<label><input
					type="radio"
					name="case_update"
					value="information"
					@click="optionChosen"
				/>
					Request information (Use if missing information necessary for you to create a case)</label>
			</div>
			<div v-if="case_entry.status === 'information'" class="form-row checkbox">
				<label><input
					type="radio"
					name="case_update"
					value="rejected"
					@click="optionChosen"
				/>
				Reject Collaboration Request (Example customer or product is not under support)</label>
			</div>
		</div>

		<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid>
			<div>
				<div class="form-section">{{ case_entry.receiver.name }} Case Information</div>
				<div v-if="accept" :class="formRowClass('case_number')">
					<input type="text" :placeholder="`${ case_entry.receiver.name } Case Number`" :aria-label="`${ case_entry.receiver.name } Case Number`" v-model="form_data.case_number" />
					<span class="error-block">{{ errors.case_number }}</span>
				</div>
				<div v-if="accept" :class="formRowClass('engineer_name')">
					<input type="text" placeholder="Assigned Engineer First and Last Name" aria-label="Assigned Engineer First and Last Name" v-model="form_data.engineer_name" />
					<span class="error-block">{{ errors.engineer_name }}</span>
				</div>
				<div v-if="accept" :class="formRowClass('engineer_email')">
					<input type="email" placeholder="Assigned Engineer Email" aria-label="Assigned Engineer Email" v-model="form_data.engineer_email" />
					<span class="error-block">{{ errors.engineer_email }}</span>
				</div>
				<div v-if="!accept" :class="formRowClass('reviewer_name')">
					<input type="text" placeholder="Your Name" aria-label="Your Name" v-model="form_data.reviewer_name" />
					<span class="error-block">{{ errors.reviewer_name }}</span>
				</div>
				<div v-if="!accept" :class="formRowClass('reviewer_email')">
					<input type="email" placeholder="Your Email" aria-label="Your Email" v-model="form_data.reviewer_email" />
					<span class="error-block">{{ errors.reviewer_email }}</span>
				</div>
				<div :class="formRowClass('engineer_phone')">
					<TelephoneField
						@country-changed="countryChanged"
						@phone-changed="onPhoneChanged"
						@is-valid="onPhoneValidated"
						placeholder="Phone (Optional)" />
					<span class="error-block">{{ errors.engineer_phone }}</span>
				</div>
			</div>
			<div>
				<div class="form-section" style="color: #666666;">&nbsp;{{ helperTitle }} </div>
				<div :class="formRowClass('next_steps')">
					<textarea :placeholder="activePlaceholder" :aria-label="activePlaceholder" rows="6" v-model="form_data.next_steps"></textarea>
					<span class="error-block">{{ errors.next_steps }}</span>
				</div>
				<div class="form-row">
					<button @click.prevent="submitResponse" class="tsa-btn tsa-btn-secondary">Submit</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex';
	import _ from 'lodash';
	import TelephoneField from '@/components/Form/TelephoneField.vue';
	export default{
		name: 'response-form',
		components: {
			TelephoneField
		},
		data(){
			return {
				form_data:{
					case_number: "",
					engineer_name: "",
					engineer_email: "",
					engineer_phone: "",
					reviewer_name: "",
					reviewer_email: "",
					next_steps: "",
					status:"accepted"
				},
				engineer_country_code: "",
				valid_engineer_number: false,
				errors: {},
				placeholderAccept: "Note: Example Instructions for next steps",
				placeholderInfo: "What information is needed to create a case",
				placeholderRejected: "Provide details on why was this collaboration rejected",
				activePlaceholder: "",
				helperTitle: "",
				helperTitleAccept: "Note: change the default note if needed",
				helperTitleInformation: "Information needed",
				helperTitleRejected: "Note: change the default note if needed",
				acceptDefaultNote:"Our assigned engineer will contact you.",

			}
		},
		mounted() {
			this.activePlaceholder = this.placeholderAccept;
			this.helperTitle = this.helperTitleAccept;
			this.form_data.next_steps = this.acceptDefaultNote;
		},
		methods:{
			/**
			 * send case response to api
			 * @return {[type]} [description]
			 */
			submitResponse(){
				this.errors = {};
				this.emitter.emit('loader',true);
				let data = _.clone(this.form_data);
				if(this.valid_engineer_number){
					data.engineer_phone = `${this.engineer_country_code}: ${data.engineer_phone}`;
				}else{
					data.engineer_phone = "";
				}
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/case/${this.case_entry.token}/respond`,data)
					.then(response => this.handleSubmitCase(response.data))
					// eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleSubmitCase(response){
				this.emitter.emit('loader',false);
				this.emitter.emit('alert',"Your response has been sent.");
				this.$store.dispatch('caseResponse',response);
				this.$emit("responded");
			},
			handleErrors (errors){
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
				this.emitter.emit('loader',false);
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			countryChanged(data){
				if(data == null) return;
				if(typeof data === 'string'){
					this.engineer_country_code = data;
				}else{
					// object
					this.engineer_country_code = data.iso2;
				}
			},
			onPhoneChanged(number){
				this.form_data.engineer_phone = number;
			},
			// eslint-disable-next-line
			onPhoneValidated(valid){
				this.valid_engineer_number = valid;
			},
			optionChosen(e){
				this.form_data.status = e.target.value;

				switch(e.target.value){
					case "accepted":
						this.activePlaceholder = this.placeholderAccept;
						this.helperTitle = this.helperTitleAccept;
						if(this.form_data.next_steps == ""){
							this.form_data.next_steps = this.acceptDefaultNote;
						}
						break;
					case "information":
						this.activePlaceholder = this.placeholderInfo;
						this.helperTitle = this.helperTitleInformation;
						if(this.form_data.next_steps == this.acceptDefaultNote){
							this.form_data.next_steps = "";
						}
						break;
					case "rejected":
						this.activePlaceholder = this.placeholderRejected;
						this.helperTitle = this.helperTitleRejected;
						if(this.form_data.next_steps == this.acceptDefaultNote){
							this.form_data.next_steps = "";
						}
						break;
				}
			}
		},
		computed:{
			...mapState({
				case_entry: state => state.case_entry
			}),
			accept(){
				return this.form_data.status === "accepted";
			},
			information(){
				return this.form_data.status === "information";
			},
			reject(){
				return this.form_data.status === "rejected";
			}
		}
	}
</script>