<template>
	<div class="tsa-partner-groups-contact tsa-partner-onboarding">
		<GroupHeader />
		<form action="" method="post" @submit.prevent="sendContactInfo">
			<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
				<!-- Company Information -->
				<div>
					<div class="form-section">Company Information</div>
					<div :class="formRowClass('company_name')">
						<input type="text" name="company_name" placeholder="Enter Company Name" aria-label="Enter Company Name" v-model="company_name" />
						<span class="error-block">{{ errors.company_name }}</span>
					</div>
					<div :class="formRowClass('company_address')">
						<input type="text" name="company_address" placeholder="Enter Address" aria-label="Enter Address" v-model="company_address" />
						<span class="error-block">{{ errors.company_address }}</span>
					</div>
					<div :class="formRowClass('company_city')">
						<input type="text" name="company_city" placeholder="Enter City" aria-label="Enter City" v-model="company_city" />
						<span class="error-block">{{ errors.company_city }}</span>
					</div>
					<div :class="formRowClass('company_state')">
						<input type="text" name="company_state" placeholder="Enter State" aria-label="Enter State" v-model="company_state" />
						<span class="error-block">{{ errors.company_state }}</span>
					</div>
					<div :class="formRowClass('company_zipcode')">
						<input type="text" name="company_zipcode" placeholder="Enter Postal Code" aria-label="Enter Postal Code" v-model="company_zipcode" />
						<span class="error-block">{{ errors.company_zipcode }}</span>
					</div>
					<div :class="formRowClass('company_url')">
					<input type="text" name="company_name" placeholder="Enter Company Website" aria-label="Enter Company Website" v-model="company_url" />
					<span class="error-block">{{ errors.company_url }}</span>
				</div>
					<!-- country -->
					<div :class="formRowClass('company_country_id')">
						<select v-model="company_country_id" aria-label="Choose Country">
							<option value="">Choose Country</option>
							<option
								v-for="(name,country_id) in countries"
								:key="country_id"
								:value="country_id">{{ name }}</option>
						</select>
						<span class="error-block">{{ errors.company_country_id }}</span>
					</div>
				</div>
				<!-- User Information -->
				<div>
					<div class="form-section">Your Information</div>
					<div :class="formRowClass('user_first_name')">
						<input type="text" name="first_name" placeholder="Enter First Name" aria-label="Enter First Name" v-model="user_first_name" />
						<span class="error-block">{{ errors.user_first_name }}</span>
					</div>
					<div :class="formRowClass('user_last_name')">
						<input type="text" name="last_name" placeholder="Enter Last Name" aria-label="Enter Last Name" v-model="user_last_name" />
						<span class="error-block">{{ errors.user_last_name }}</span>
					</div>
					<div :class="formRowClass('user_email')">
						<input type="email" name="email" placeholder="Enter Email Address" aria-label="Enter Email Address" v-model="user_email" />
						<span class="error-block">{{ errors.user_email }}</span>
					</div>
					<div :class="formRowClass('user_phone')">
						<TelephoneField
							@country-changed="countryChanged"
							@phone-changed="onPhoneChanged"
							placeholder="Enter Phone" />
						<span class="error-block">{{ errors.user_phone }}</span>
					</div>
					<div :class="formRowClass('user_password')">
						<input type="password" name="password" placeholder="Enter Password" aria-label="Enter Password" v-model="user_password" />
						<span class="error-block">{{ errors.user_password }}</span>
					</div>
					<!-- password confirmation -->
					<div class="form-row">
						<input type="password" name="password_confirmation" placeholder="Confirm Password" aria-label="Confirm Password" v-model="user_password_confirmation" />
					</div>
					<div class="form-row tsa-note small text-left" style="margin-top: 20px;">
						<PasswordRequirements />
					</div>
				</div>
			</div>
			<div class="tsa-register-footer">
				<div class='tsa-progress-wrapper'>
					<Progress section="contact" />
				</div>
				<button class="tsa-btn" @click.prevent="sendContactInfo">Next</button>
				&nbsp;&nbsp;
				<a href="#" @click.prevent="saveForLater"><small>Save and Finish Later</small></a>
			</div>
		</form>
	</div>
</template>
<script>
	import GroupHeader from '@/components/PartnerGroup/Header.vue';
	import Progress from '@/components/PartnerGroup/Progress.vue';
	import PasswordRequirements from '@/components/Layout/PasswordRequirements.vue';
	import TelephoneField from '@/components/Form/TelephoneField.vue';
	import {mapState} from 'vuex';
	import _ from 'lodash';
	import RegistrationSaver from "../../Mixins/RegistrationSaver.vue";

	export default {
		name: 'onboarding-contact',
		components: {
			GroupHeader,
			Progress,
			PasswordRequirements,
			TelephoneField
		},
		mixins:[
			RegistrationSaver
		],
		data(){
			return {
				saving_for_later: false,
				v_user_phone: "",
				errors: {}
			}
		},
		mounted(){
			if(!this.partner_group || !this.partner_group.id){
				this.loadRegistrationData();
				this.user_email = this.session.email;
			}
		},
		methods: {
			sendContactInfo(){
				this.emitter.emit('loader',true);
				let data = _.cloneDeep(this.onboarding);
				data.session_token = this.session_token;
				if(this.saving_for_later) data.save_for_later = true;
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/validate_contact`,data)
					.then(response => this.onSendContactInfo(response.data))
					//eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			onSendContactInfo(response){
				this.emitter.emit('loader',false);
				if(response.valid && !this.saving_for_later){
					this.$router.push({name:'onboarding.agreements'});
				}else{
					this.emitter.emit('alert',{
						type: "success",
						message: response.message
					});
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * handle validation errors.
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					const field = e.replace('.','_');
					let string = errors[e][0];
					this.errors[field] = string.replace('user.','').replace('company.','');
				}
			},
			countryChanged(data){
				if(data == null) return;
				if(typeof data === 'string'){
					this.user_country_code = data;
				}else{
					// object
					this.user_country_code = data.iso2;
				}
			},
			onPhoneChanged(number){
				this.user_phone = number;
			},
			/**
			 * make that the user is trying to save their progress for later
			 * @return {[type]} [description]
			 */
			saveForLater(){
				this.saving_for_later = true;
				this.sendContactInfo();
			}
		},
		computed: {
			company_name: {
				get() { return this.$store.state.onboarding.company.name},
				set(value){ 
					this.$store.commit('updateOnboardingCompany',{
					field: 'name',
					value: value
					});
				}
			},
			company_address: {
				get() { return this.$store.state.onboarding.company.address},
				set(value){ 
					this.$store.commit('updateOnboardingCompany',{
					field: 'address',
					value: value
					});
				}
			},
			company_city: {
				get() { return this.$store.state.onboarding.company.city},
				set(value){ 
					this.$store.commit('updateOnboardingCompany',{
					field: 'city',
					value: value
					});
				}
			},
			company_state: {
				get() { return this.$store.state.onboarding.company.state},
				set(value){ 
					this.$store.commit('updateOnboardingCompany',{
					field: 'state',
					value: value
					});
				}
			},
			company_zipcode: {
				get() { return this.$store.state.onboarding.company.zipcode},
				set(value){ 
					this.$store.commit('updateOnboardingCompany',{
					field: 'zipcode',
					value: value
					});
				}
			},
			company_country_id: {
				get() { return this.$store.state.onboarding.company.country_id},
				set(value){ 
					this.$store.commit('updateOnboardingCompany',{
					field: 'country_id',
					value: value
					});
				}
			},
			company_url: {
				get() { return this.$store.state.onboarding.company.url},
				set(value){
					this.$store.commit('updateOnboardingCompany',{
						field: 'url',
						value: value
					});
				}
			},
			// user
			user_first_name: {
				get() { return this.$store.state.onboarding.user.first_name},
				set(value){ 
					this.$store.commit('updateOnboardingUser',{
					field: 'first_name',
					value: value
					});
				}
			},
			user_last_name: {
				get() { return this.$store.state.onboarding.user.last_name},
				set(value){ 
					this.$store.commit('updateOnboardingUser',{
					field: 'last_name',
					value: value
					});
				}
			},
			user_email: {
				get() { return this.$store.state.onboarding.user.email},
				set(value){ 
					this.$store.commit('updateOnboardingUser',{
					field: 'email',
					value: value
					});
				}
			},
			user_country_code: {
				get() { return this.$store.state.onboarding.user.phone_country_code},
				set(value){ 
					this.$store.commit('updateOnboardingUser',{
					field: 'phone_country_code',
					value: value
					});
				}
			},
			user_phone: {
				get() { return this.$store.state.onboarding.user.phone},
				set(value){ 
					this.$store.commit('updateOnboardingUser',{
					field: 'phone',
					value: value
					});
				}
			},
			user_password: {
				get() { return this.$store.state.onboarding.user.password},
				set(value){ 
					this.$store.commit('updateOnboardingUser',{
					field: 'password',
					value: value
					});
				}
			},
			user_password_confirmation: {
				get() { return this.$store.state.onboarding.user.password_confirmation},
				set(value){ 
					this.$store.commit('updateOnboardingUser',{
					field: 'password_confirmation',
					value: value
					});
				}
			},
			...mapState({
				countries: state => state.countries,
				onboarding: state => state.onboarding,
				partner_group: state => state.partner_group,
				session_token: state => state.onboarding_session_token,
				session: state => state.onboarding_session
			})
		}
	}
</script>