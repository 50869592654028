<template>
	<div>
		<quill-editor :id="fieldId" ref="editor" :key="keyName + '_' + buildCount" v-model="starterContent" :placeholder="placeholder" @change="changeListener"></quill-editor>
	</div>
</template>

<script>
import QuillEditor from "./QuillEditor.vue";
export default {
	name: "WysiwygEditor",

	props: {
		modelValue: String,
		placeholder:{
			type: String,
			default: ''
		},
		keyName:{
			type: String,
			default: ''
		},
		fieldId:{
			type: String,
			default: ''
		}
	},
	components: {
		QuillEditor
	},
	data() {
		return {
			starterContent:"",
			currentContent:"",
			buildCount: 0
		}
	},
	mounted() {
		if(this.modelValue && this.modelValue !== ''){
			this.starterContent = this.modelValue;
		}
	},
	methods: {
		changeListener(){
			this.currentContent = this.getContent();
			this.$emit('update:modelValue', this.currentContent);
		},
		getContent(){
			return this.$refs.editor.getContent();
		}
	},
	watch: {
		modelValue:{
			handler(val){
				if(val && val !== '' && val !== '<p></p>' && (this.currentContent === '' || this.currentContent === '<p></p>')){
					this.currentContent = this.starterContent = val;
					setTimeout(() => {this.buildCount++;}, 1);
				}
			},
			immediate: true
		}
	}
}
</script>