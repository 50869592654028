<template>
    <DefaultModal
        title="Update Agreement Status"
        :is_open="opened"
        @close="onCloseModal">
        <p>We've updated our privacy policy. Please review and agree to the <a href="https://www.tsanet.org/legal-policy/" target="_blank">updated policy</a>.</p>
        <div class="alert-scrollable">
            <div class="uk-grid-small uk-flex-center" uk-grid style="padding: 0 40px;">
                <div class="uk-width-1-1">
                    <div class="form-row tsa-note" :class="formRowClass('agree_confirm')" style="margin-top: 20px;width: 100%;text-align: center;">
						<label><input type="checkbox" v-model="form_data.agree_confirm" />
						I agree to the <a href="https://www.tsanet.org/legal-policy/" target="_blank">TSANet Privacy Policy</a></label>
						<span class="error-message">You must accept the terms of the privacy policy.</span>
					</div>
                </div>
            </div>
        </div>
        <div style="padding: 40px 40px 20px 40px;" class="clear_after">
            <button class='tsa-btn pull-right ' @click.prevent="updateAgreement">Agree</button>
        </div>
    </DefaultModal>
</template>

<script>
    // import any required components
    import DefaultModal from '@/components/Layout/DefaultModal.vue';
    import { mapState } from 'vuex';
    import _ from 'lodash';
    // export component
    export default {
        // properties that the component will receive from parent
        props: {
            opened : {
                type : Boolean,
                default : false
            }
        },
        // components to use in template
        components:{
            DefaultModal,
        },
        // component data
        data() {
            return {
                form_data: {
					agree_confirm: false,
                },
                errors: {},
            };
        },
        // component was mounted
        mounted(){
			if(this.user.agreement_date !== null && this.user.agreement_date !== ""){
				this.form_data.agree_confirm = true;
			}
        },
        // any methods needed for this component
        methods: {
            onCloseModal(){
                this.$emit('close');
            },
            /**
             * 'form-row' add 'has-error' class if field has error
             * @param  {[type]} field [description]
             * @return {[type]}       [description]
             */
            formRowClass(field){
                let string = "";
                if(this.errors[field] != undefined ) string += " has-error";
                return string;
            },
            /**
             * send request to update profile
             * @return {[type]} [description]
             */
            updateAgreement(){
                // clear all errors
                this.errors = {};
                this.emitter.emit('loader',true);
                // eslint-disable-next-line
                axios.post(`${process.env.VUE_APP_API_URL}users/agreement`,{agree_confirm:this.form_data.agree_confirm})
                    .then(response => this.handlePostResponse(response.data))
                    // eslint-disable-next-line
                    .catch(error => this.handleErrors(error.response.data.errors));
            },
            /**
             * on profile saved.
             * @param  {[type]} response [description]
             * @return {[type]}          [description]
             */
            handlePostResponse(response){
                this.emitter.emit('loader',false);
                this.$store.dispatch('user',{user: response});
                this.emitter.emit('alert',"Profile Updated");
                this.$emit('close');
            },
            handleErrors (errors){
                this.emitter.emit('loader',false);
                // clear all errors
                this.errors = {};
                for(var e in errors){
                    this.errors[e] = errors[e][0];
                }
            },
            loadProfileFormData(){
                // eslint-disable-next-line
                axios.get(`${process.env.VUE_APP_API_URL}users/profile_form_data`)
                .then(response => this.onProfileFormData(response.data))
                // eslint-disable-next-line
                .catch(error => console.log(error));
            },
            /**
             * save form data in store
             * @return {[type]} [description]
             */
            onProfileFormData(response){
                this.$store.dispatch('profileFormData',response);
                this.cloneUser();
            },
            /**
             * clone user into form data.
             * @return {[type]} [description]
             */
            cloneUser(){
                let data = _.clone(this.user);
                if(data.phone == null) data.phone = "";
                if(data.country_id == null || data.country_id == undefined) data.country_id = "";
                data.password = "";
                data.password_confirmation = "";
                if(data.phone_country_code == null) data.phone_country_code = "US";
                this.form_data = data;
				if(this.user.agreement_date !== null && this.user.agreement_date !== ""){
					this.form_data.agree_confirm = true;
				}
            },
        },
        // computed properties
        computed:{
            ...mapState({
                user: state => state.user,
                countries : state => state.countries,
                company : state => state.company
            })
        },
        // property watchers
        watch:{
            opened(){
                if(this.opened && this.countries.length == 0){
                    this.loadProfileFormData();
                }
            }
        }
    }
</script>