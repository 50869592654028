<template>
	<div class="tsa-partner-groups-process-document tsa-partner-onboarding">
		<GroupHeader />
		<div class="onboarding-instructions">
			<HelpHint flag="limited_create_support_process" /> <span class="page-name">Your Inbound Process &mdash;</span> When completed an email will be sent to the support manager.
		</div>
		<div v-show="!view_sample">
			<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
				<div>
					<div :class="formRowClass('support_hours')" class="with-label">
						<label for="ob_hours">Support Hours:</label>
						<input id="ob_hours" type="text" placeholder="Enter Support Hours" v-model="support_hours" />
						<span class="error-block">{{ errors.support_hours }}</span>
					</div>
					<div :class="formRowClass('product_supported')" class="with-label">
						<label for="ob_products">Products Supported:</label>
						<input id="ob_products" type="text" placeholder="Enter Products Supported" v-model="product_supported" />
						<span class="error-block">{{ errors.product_supported }}</span>
					</div>
					<div :class="formRowClass('support_email')" class="with-label">
						<label for="ob_email">Support Email Alias</label>
						<input id="ob_email" type="email" placeholder="Enter Support Email Alias" v-model="support_email" />
						<span class="error-block">{{ errors.support_email }}</span>
					</div>
					<div :class="formRowClass('manager_first_name')" class="with-label">
						<label for="ob_mf_name">Support Manager First Name</label>
						<input id="ob_mf_name" type="text" placeholder="Enter Support Manager First Name" v-model="manager_first_name" />
						<span class="error-block">{{ errors.manager_first_name }}</span>
					</div>
					<div :class="formRowClass('manager_last_name')" class="with-label">
						<label for="ob_mf_last">Support Manager Last Name</label>
						<input id="ob_mf_last" type="text" placeholder="Enter Support Manager Last Name" v-model="manager_last_name" />
						<span class="error-block">{{ errors.manager_last_name }}</span>
					</div>
					<div :class="formRowClass('manager_email')" class="with-label">
						<label for="ob_support_email">Support Manager's Email</label>
						<input id="ob_support_email" type="email" placeholder="Enter Support Manager's Email" v-model="manager_email" />
						<span class="error-block">{{ errors.manager_email }}</span>
					</div>
				</div>
				<div>
					<div class="with-label" :class="formRowClass('support_phone')">
						<label for="ob_phone">Enter your support phone number</label>
						<TelephoneField
							field-id="ob_phone"
							@phone-changed="onPhoneChanged"
							@country-changed="onCountryChanged"
							placeholder="Enter Your Phone Number" />
						<span class="error-block">{{ errors.support_phone }}</span>
					</div>
					<div class="form-row with-label">
						<span>&nbsp;</span>
						<button class="tsa-btn tsa-plain" @click.prevent="viewSampleForm">View Sample Form</button>
					</div>
					<div class="form-row with-label save-form">
						<span>&nbsp;</span>
						<button class="tsa-btn" @click.prevent="verifyProcessDocument">Submit</button>
						&nbsp;&nbsp;
						<a href="#" @click.prevent="saveForLater"><small>Save and Finish Later</small></a>
					</div>
				</div>
			</div>
			<div class="tsa-register-footer">
				<div class='tsa-progress-wrapper'>
					<Progress section="process" />
				</div>
			</div>
		</div>
		<div v-show="view_sample">
			<DocumentSample
				@close="closeSample" />
		</div>
	</div>
</template>

<script>
	import GroupHeader from '@/components/PartnerGroup/Header.vue';
	import Progress from '@/components/PartnerGroup/Progress.vue';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import DocumentSample from '@/components/PartnerGroup/DocumentSample.vue';
	import TelephoneField from '@/components/Form/TelephoneField.vue';
	import RegistrationSaver from "../../Mixins/RegistrationSaver.vue";
	import _ from 'lodash';
	import {mapState} from 'vuex';

	export default {
		name: 'onboarding-process-document',
		components: {
			GroupHeader,
			Progress,
			HelpHint,
			DocumentSample,
			TelephoneField
		},
		mixins:[
			RegistrationSaver
		],
		data(){
			return {
				view_sample: false,
				saving_for_later: false,
				errors: {},
				local_escalation: "",
				v_support_phone : "",
			}
		},
		methods: {
			viewSampleForm(){
				this.view_sample = true;
			},
			closeSample(){
				this.view_sample = false;
			},
			verifyProcessDocument(){
				this.emitter.emit('loader',true);
				let data = _.cloneDeep(this.onboarding);
				data.session_token = this.session_token;
				if(this.saving_for_later) data.save_for_later = true;
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/register`,data)
					.then(response => this.onRegister(response.data))
					//eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			onRegister(response){
				this.emitter.emit('loader',false);
				if(response.valid && !this.saving_for_later){
					this.$store.dispatch('resetOnboarding');
					this.$router.push({name:'onboarding.success'});
				}else{
					this.emitter.emit('alert',{
						type: "success",
						message: response.message
					});
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * handle validation errors.
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					const field = e.replace('document.','');
					let string = errors[e][0];
					this.errors[field] = string.replace('document.','');
				}
			},
			/**
			 * make that the user is trying to save their progress for later
			 * @return {[type]} [description]
			 */
			saveForLater(){
				this.saving_for_later = true;
				this.verifyProcessDocument();
			},
			onPhoneChanged(phone){
				this.support_phone = phone;
			},
			onCountryChanged(country){
				this.support_country_code = country;
			}
		},
		computed: {
			escalationInstructions(){
				return 'Provide additional contact information for TSANet Members if they do not get a response from you. Examples would be individual(s) contact information that are aware of the TSANet process (e.g "Call our support department at 800-555-1212 and ask for the duty manager")';
			},
			support_hours: {
				get() { return this.$store.state.onboarding.document.support_hours},
				set(value){ 
					this.$store.commit('updateOnboardingDocument',{
					field: 'support_hours',
					value: value
					});
				}
			},
			product_supported: {
				get() { return this.$store.state.onboarding.document.product_supported},
				set(value){ 
					this.$store.commit('updateOnboardingDocument',{
					field: 'product_supported',
					value: value
					});
				}
			},
			support_email: {
				get() { return this.$store.state.onboarding.document.support_email},
				set(value){ 
					this.$store.commit('updateOnboardingDocument',{
					field: 'support_email',
					value: value
					});
				}
			},
			manager_email: {
				get() { return this.$store.state.onboarding.document.manager_email},
				set(value){ 
					this.$store.commit('updateOnboardingDocument',{
					field: 'manager_email',
					value: value
					});
				}
			},
			support_phone: {
				get() { return this.$store.state.onboarding.document.support_phone},
				set(value){ 
					this.$store.commit('updateOnboardingDocument',{
					field: 'support_phone',
					value: value
					});
				}
			},
			support_country_code: {
				get() { return this.$store.state.onboarding.document.support_country_code},
				set(value){
					this.$store.commit('updateOnboardingDocument',{
					field: 'support_country_code',
					value: value
					});
				}
			},
			manager_first_name: {
				get() { return this.$store.state.onboarding.document.manager_first_name},
				set(value){ 
					this.$store.commit('updateOnboardingDocument',{
					field: 'manager_first_name',
					value: value
					});
				}
			},
			manager_last_name: {
				get() { return this.$store.state.onboarding.document.manager_last_name},
				set(value){ 
					this.$store.commit('updateOnboardingDocument',{
					field: 'manager_last_name',
					value: value
					});
				}
			},
			...mapState({
				partner_group: state => state.partner_group,
				onboarding: state => state.onboarding,
				session_token: state => state.onboarding_session_token
			})
		}
	}
</script>