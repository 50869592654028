<template>
		<div class="tsa-search-box">
			<div class="loader" v-if="!company_directory_available"><i class="fas fa-spinner fa-spin"></i></div>
			<input type="text"
				@keyup="onKeyUp"
				@keydown="onKeyDown"
				:disabled="!company_directory_available"
				placeholder="Find a Partner / Department ..." v-model="term" aria-label="Find a Partner / Department ...">
			<button @click="filterResults" class='tsa-btn search' aria-label="click to search partners"><i class="fas fa-search"></i></button>
			<div class="autocomplete-suggestions" v-show="term.length >= 2">
				<i class="fas fa-times tsa-close" @click.prevent="clearSearch"></i>
				<div v-for="(row,index) in results"
					class="autocomplete-suggestion"
					:class="inviteRow(row)"
					:key="index">
						<div
							@click.prevent="memberSelected(row)"
							v-show="row.has_access">
							<span @click="resultSelected(row)" v-html="highlightMatchedResult(row.name)" ></span> - <a href="">Collaborate</a>
						</div>
						<div class="invite"
							v-show="!row.has_access"
							@click.prevent="inviteMember(row)">
							<strong>{{row.name}}</strong> does not have a relationship with you.  If you would like a relationship - <a href="#" class="">Send Invite</a>
						</div>
				</div>
			<div class="tsa-no-results"><a href="" @click.prevent="missingPartner">Not able to find your partner?</a></div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: 'search-partner-box',
		components:{

		},
		data(){
			return {
				results: [],
				term: "",
				typing_interval : null,
				typing_interval_time : 1000,
				search_id : null
			}
		},
		methods:{
			/**
        	 * filter results based on entered term
        	 * @return void
        	 */
			filterResults(){
				this.results = []; // clear prev results.
				if( this.term.length <= 1 ) return;
				var lowerTerm = this.term.toLowerCase();
				for(var i in this.company_directory){
					//var member = this.company_directory[i]._source;
					//if(member.company_name.toLowerCase().indexOf(lowerTerm) != -1){
					var member = this.company_directory[i];
					const matchOnName = member.company_name.toLowerCase().indexOf(lowerTerm) != -1 || (member.department_name != "" && member.department_name.toLowerCase().indexOf(lowerTerm) != -1);
					// check matches on tags
					let matchOnTag = null;
					if(member.tags.length > 0){
						for(let t in member.tags){
							const tag = member.tags[t];
							// see if the tag matches the search term
							if(tag.tag.toLowerCase().indexOf(lowerTerm) != -1){
								matchOnTag = tag.tag;
								break;
							}
						}
					}
					// check if there's a match
					if(matchOnName === true || matchOnTag != null){
						let name = (member.type == "department")?  member.company_name + " | " + member.department_name : member.company_name;
						if(matchOnTag){
							name += " - " + matchOnTag;
						}
						this.results.push({
							id: (member.type == "company")? member.company_id : member.department_id ,
							name: name,
							type: member.type,
							has_access: member.has_access
						});
					}
				}
			},
			/**
			* hightlight the entered term in string (set strong tag)
			* @param  {string} value source string
			* @return {string} html string
			*/
            highlightMatchedResult(value){
					var pattern = '(' + this.escapeRegExChars(this.term) + ')';
					value = value + ""; // force string value
					return value
					// eslint-disable-next-line
					.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')
					.replace(/&/g, '&amp;')
					.replace(/</g, '&lt;')
					.replace(/>/g, '&gt;')
					.replace(/"/g, '&quot;')
					.replace(/&lt;(\/?strong)&gt;/g, '<$1>');
			},
			/**
			* function name says it all
			* @param  {string} value [description]
			* @return escaped string
			*/
			escapeRegExChars: function (value) {
				return value.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
			},
			resultSelected(){

			},
			/**
			* set class if row is for an invite result
			* @param  {object} row search result object
			* @return {string}     class name invite-row
			*/
			inviteRow(row){
				return (!row.has_access)? 'invite-row' : "";
			},
			/**
			 * display screen to invite a member.
			 * @param  {[type]} member [description]
			 * @return {[type]}        [description]
			 */
			inviteMember(member){
				this.term = "";
				this.emitter.emit('invite_member',member);
			},
			/**
			 * user selected a member
			 * link the selected result to the search id previously selected
			 * then redirect to process document.
			 * @param  {[type]} member [description]
			 * @return {[type]}        [description]
			 */
			memberSelected(member){
				// make sure we have a search id before allowing users to collaborate
				// that way we can save the result
				if(this.search_id == null) return false;
				this.emitter.emit('loader',true);
				var data = {
					member : member,
					search_id : this.search_id
				};
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/result_to_search`,data)
				//eslint-disable-next-line
					.then(response => this.collaborate(member))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * user wants to collaborate with member
			 * take to submit case.
			 * @param  {[type]} member [description]
			 * @return {[type]}        [description]
			 */
			collaborate(member){
				this.$router.push({name:'submit-case',params:{type: member.type, entity_id: member.id}});
			},
			/**
			 * show screen to report a missing partner
			 * @return {[type]} [description]
			 */
			missingPartner(){
				this.term = "";
				this.emitter.emit('missing_partner');
			},
			clearSearch(){
				this.term = "";
				this.search_id = null;
			},
			onKeyUp(){
				clearTimeout(this.typing_interval);
				this.typing_interval = setTimeout(() => this.doneTyping(), this.typing_interval_time);
			},
			onKeyDown(){
				clearTimeout(this.typing_interval);
			},
			// user is done type
			doneTyping(){
				if(this.term == "") return;

				const data = {
					term : this.term,
					location : "collaborate"
				};

				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/save_search`,data)
				//eslint-disable-next-line
				.then(response => this.handleSaveSearch(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleSaveSearch(response){
				this.search_id = response.search.id;
			}
		},
		watch:{
			term(){
				this.filterResults();
			}
        },
		computed:{
			...mapState({
				company_directory: state => state.company_directory,
				company_directory_available: state => state.company_directory_available
			})
		}
	}
</script>