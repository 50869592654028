<template>
	<div class="tsa-partner-group-home">
		<GroupHeader />
		<div class="uk-grid-large" uk-grid>
			<div class="uk-width-expand">
				<div class="tsa-about-group">
					<div class="tsa-section-title">About this Program:</div>
					<div v-html="partner_group.instructions"></div>
					<div class="tsa-register">
						<div class="tsa-section-title">
							Register Now: <small>Enter your Email below to start the registration process.</small>
						</div>
						<div class="form-row with-inline-button"  :class="formRowClass('email')" uk-grid>
							<div style="position: relative;">
								<input type="email" placeholder="Enter Your Work Email Address" aria-label="Work Email" @keydown.enter="validateEmail" v-model="email">
								<span class="error-block">{{ errors.email }}</span>
							</div>
							<button class="tsa-btn" @click.prevent="validateEmail">Next</button>
						</div>
						<div class="form-row">
							<h6><a :href="testAppLink">Contact us</a> if you would like to submit a test application.</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="uk-width-1-4@m">
				<div class="helpful-links">
					<div class="tsa-section-title">Helpful Links</div>
					<div v-html="partner_group.links"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import GroupHeader from '@/components/PartnerGroup/Header.vue';
	import RegistrationSaver from "../../Mixins/RegistrationSaver.vue";

	export default {
		name: 'partner-home',
		components: {
			GroupHeader
		},
		props: {
		},
		mixins:[
			RegistrationSaver
		],
		data(){
			return {
				errors: {}
			}
		},
		mounted(){
			this.saveRegistrationData();
		},
		methods: {
			validateEmail(){
				this.emitter.emit('loader',true);
				let data = {
					partner_group_id: this.partner_group.id,
					email: this.email
				}
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/verify_email`,data)
					.then(response => this.handleValidateEmail(response.data))
					//eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * [handleValidateEmail description]
			 * @return {[type]} [description]
			 */
			handleValidateEmail(response){
				this.emitter.emit('loader',false);
				if(!response.valid){
					this.emitter.emit('alert',{
						type: "warning",
						message: response.message
					});
					this.$router.push(response.redirect);
				}else{
					this.$store.dispatch('onboardingSession',response.session);
					this.saveRegistrationSession(response.session);
					if(response.session.company != null){
						// review agreements.
						if(response.session.company.membership_type == "premium" || 
							response.session.company.membership_type == "basic" ||
							response.session.company.membership_type == "standard"){
							this.$router.push({name:'onboarding.basic-premium'});
						}else{
							this.$router.push({name:'onboarding.agreements'});
						}
					}else{
						this.$router.push({name: 'onboarding.contact'});
					}
				}
			},
			/**
			 * handle validation errors.
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
		},
		computed: {
			testAppLink(){
				const subject = `TSANET -- Test Application for ${this.partner_group.name}.`;
				const body = `Hi, I would like to submit a test application for ${this.partner_group.name}`;
				return `mailto:membership@tsanet.org?subject=${subject}&body=${body}`;
			},
			email: {
				get() { return this.$store.state.onboarding.user.email},
				set(value){ 
					this.$store.commit('updateOnboardingUser',{
					field: 'email',
					value: value
					});
				}
			},
			...mapState({
				partner_group: state => state.partner_group
			})
		}
	}
</script>