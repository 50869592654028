<template>
	<div class="tsa-register-form">
		<div v-if="!requires_approval">
			<div class="tsa-section-title">Need an Account?</div>
			<form @submit.prevent="submitRegistration">
				<div class="uk-child-width-1-2@s uk-grid-small" uk-grid>
					<div :class="formRowClass('first_name')">
						<input type="text" name="first_name" placeholder="First Name" v-model="form_data.first_name" aria-label="First Name" />
						<span class="error-block">{{ errors.first_name }}</span>
					</div>
					<div :class="formRowClass('last_name')">
						<input type="text" name="last_name" placeholder="Last Name" v-model="form_data.last_name" aria-label="Last Name" />
						<span class="error-block">{{ errors.last_name }}</span>
					</div>
					<div :class="formRowClass('email')">
						<input type="email" name="email" placeholder="Work Email" v-model="form_data.email" aria-label="Work Email" />
						<span class="error-block">{{ errors.email }}</span>
					</div>
					<div :class="formRowClass('phone')">
						<label for="re_phone" style="display: none">Phone</label>
						<TelephoneField
							field-id="re_phone"
							@country-changed="countryChanged"
							@phone-changed="onPhoneChanged"
							placeholder="Phone Number" />
						<span class="error-block">{{ errors.phone }}</span>
					</div>
					<!-- country -->
					<div :class="formRowClass('country_id')">
						<select v-model="form_data.country_id" aria-label="Choose Country">
							<option value="">Choose Country</option>
							<option
								v-for="(name,country_id) in countries"
								:key="country_id"
								:value="country_id">{{ name }}</option>
						</select>
						<span class="error-block">{{ errors.country_id }}</span>
					</div>
					<div :class="formRowClass('city')">
						<input type="text" name="city" placeholder="City" v-model="form_data.city" aria-label="City" />
						<span class="error-block">{{ errors.city }}</span>
					</div>
					<div :class="formRowClass('password')">
						<input type="password" name="password" placeholder="Password" v-model="form_data.password" aria-label="Password" />
						<span class="error-block">{{ errors.password }}</span>
					</div>
					<div class="form-row">
						<input type="password" name="password_confirmation" placeholder="Confirm Password" v-model="form_data.password_confirmation" aria-label="Confirm Password" />
					</div>
					<div class="form-row tsa-note" :class="formRowClass('agree_confirm')" style="margin-top: 20px;width: 100%;">
						<label><input type="checkbox" v-model="form_data.agree_confirm" />
						I agree to the <a href="https://www.tsanet.org/legal-policy/" target="_blank">TSANet Privacy Policy</a></label>
						<span class="error-message">You must accept the terms of the privacy policy.</span>
					</div>
					<div class="form-row tsa-note small text-left" style="margin-top: 20px;">
						<PasswordRequirements />
					</div>
					<div class="form-row text-right"><button class="tsa-btn" @click.prevent="submitRegistration">Register</button></div>
				</div>
			</form>
		</div>
		<div v-else>
			<div class="tsa-section-title">Your Account Requires Approval</div>
			<div class="verify-message">
				Your request to create a User Account is now under review by your TSANet Business Manager.   If you do not receive an email after 24 hours, please contact TSANet <a href="mailto:membership@tsanet.org">membership@tsanet.org</a> or call us at <a href="tel:+1 913-345-9311">+1 913-345-9311</a>.
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from "vuex";
	import PasswordRequirements from '@/components/Layout/PasswordRequirements.vue';
	import TelephoneField from '@/components/Form/TelephoneField.vue';

	export default {
		name: 'register-form',
		components: {
			PasswordRequirements,
			TelephoneField
		},
		data(){
			return {
				v_phone : "",
				form_data:{
					first_name: "",
					last_name: "",
					email: "",
					phone: "",
					country_id: "",
					phone_country_code : null,
					city: "",
					password: "",
					password_confirmation: ""
				},
				errors:{

				},
				requires_approval: false
			}
		},
		methods: {
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * submit registration data.
			 * @return {[type]} [description]
			 */
			submitRegistration(){
				// show loader
				this.emitter.emit('loader',true);
				// clear all errors
				this.errors = {}
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/register/${this.company.slug}`,this.form_data)
					.then(response => this.handleRegisterResponse(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * if success save token
			 * @return {token} [description]
			 */
			handleRegisterResponse(response){
				// hide loader
				this.emitter.emit('loader',false);
				// no errors?
				if(response.error == undefined){
					// success
					if(response.approval == true){
						this.requires_approval = true;
					}else{
						this.emitter.emit('alert',response.message);
					}
				}else{
					// alert error
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}
			},
			/**
			* display validation errors
			* @param  {[type]} errors [description]
			* @return {[type]}        [description]
			*/
			handleErrors (errors){
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			countryChanged(data){
				if(data == null) return;
				if(typeof data === 'string'){
					this.form_data.phone_country_code = data;
				}else{
					// object
					this.form_data.phone_country_code = data.iso2;
				}
			},
			onPhoneChanged(number){
				this.form_data.phone = number;
			},
		},
		computed: {
			...mapState({
				countries: state => state.countries,
				company: state => state.company
			})
		}
	}
</script>