<template>
	<div v-if="canShowControls" class="case-info-controls">
		<button v-if="canCloseCase" type="button" class="tsa-btn" @click="confirmClose">Close</button>
		<button v-if="canUpdateEngineer" uk-toggle="target: #response-update-form" class="tsa-btn" type="button">Update</button>
		<button v-if="canAddNote" :uk-toggle="'target: #' + noteFormId" class="tsa-btn" type="button">Add Note</button>
		<div id="response-update-form" ref="responseUpdateForm" uk-modal>
			<div class="uk-modal-dialog uk-modal-body">
				<button class="uk-modal-close uk-modal-close-full" type="button" aria-label="close">
					<i class="fa fa-times"></i>
				</button>
				
				<div>
					<div class="form-section">Update {{ caseEntry.receiver.name }} Case Information</div>
					<div :class="formRowClass('case_number')">
						<label :for="'ci_case_number_' + this.uniqueId">{{ caseLabel }}:</label>
						<input :id="'ci_case_number_' + this.uniqueId" type="text" :placeholder="`${ caseEntry.receiver.name } Case Number`" v-model="formData.case_number" />
						<span class="error-block">{{ errors.case_number }}</span>
					</div>
					<div :class="formRowClass('engineer_name')">
						<label :for="'ci_engineer_name_' + this.uniqueId">{{ nameLabel }}:</label>
						<input :id="'ci_engineer_name_' + this.uniqueId" type="text" placeholder="Assigned Engineer First and Last Name" v-model="formData.engineer_name" />
						<span class="error-block">{{ errors.engineer_name }}</span>
					</div>
					<div :class="formRowClass('engineer_email')">
						<label :for="'ci_engineer_email_' + this.uniqueId">{{ emailLabel }}:</label>
						<input :id="'ci_engineer_email_' + this.uniqueId" type="email" placeholder="Assigned Engineer Email" v-model="formData.engineer_email" />
						<span class="error-block">{{ errors.engineer_email }}</span>
					</div>
					<div :class="formRowClass('engineer_phone')">
						<label :for="'ci_engineer_phone_' + this.uniqueId">{{ phoneLabel }}:</label>
						<TelephoneField
							:field-id="'ci_engineer_phone_' + this.uniqueId"
							:key="'efone_' + phoneLoaded"
							@country-changed="countryChanged"
							@phone-changed="onPhoneChanged"
							@is-valid="onPhoneValidated"
							:phone="formData.engineer_phone"
							placeholder="Phone (Optional)" />
						<span class="error-block">{{ errors.engineer_phone }}</span>
					</div>
					<div class="form-row">
						<button class="tsa-btn" type="button" @click.prevent="submitResponseUpdate">Update Response</button>
					</div>
				</div>
			</div>
		</div>
		<div :id="noteFormId" ref="addNoteForm" uk-modal>
			<div class="uk-modal-dialog uk-modal-body">
				
				<button class="uk-modal-close uk-modal-close-full" type="button" aria-label="close">
					<i class="fa fa-times"></i>
				</button>
				<div>
					<div class="form-section">Add a Note</div>
					<div :class="formRowClass('case_number')">
						<label :for="'ci_summary_' + this.uniqueId">Summary:</label>
						<input :id="'ci_summary_' + this.uniqueId" type="text" placeholder="Summary" v-model="noteData.summary" />
						<span class="error-block">{{ errors.summary }}</span>
					</div>
					<div :class="formRowClass('engineer_name')">
						<label :for="'ci_description_' + this.uniqueId">Description:</label>
						<wysiwyg-editor :fieldId="'ci_description_' + this.uniqueId" ref="descriptionEditor" v-model="noteData.description"></wysiwyg-editor>
						<span class="error-block">{{ errors.description }}</span>
					</div>
					<div class="form-row">
						<button class="tsa-btn" type="button" @click.prevent="submitNote">Add Note</button>
					</div>
				</div>
			</div>
		</div>
		<confirm-modal :is_open="checkingCloseConfirmation" @confirm="closeCase" @cancel="cancelClose" cancel_text="Not Yet" confirm_text="Yes, we're done!" >
			Are you sure you want to permanently close this case?
		</confirm-modal>
	</div>
</template>

<script>
import TelephoneField from '@/components/Form/TelephoneField.vue';
import {mapState} from "vuex";
import Filters from "@/Mixins/Filters.vue";
import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";
import ConfirmModal from "@/components/Layout/ConfirmModal.vue";
export default {
	name: "CaseInfoControls",
	
	props: {
		caseEntry: {
			type: Object,
			default: () => {}
		},
		buttonType:{ // note, engineer, close
			type: String,
			default: null
		}
	},
	mixins: [Filters],
	components: {
		ConfirmModal,
		TelephoneField,
		WysiwygEditor
	},
	data() {
		return {
			uniqueId: null,
			caseResponse: null,
			formData: {
				case_number: "",
				engineer_name: "",
				engineer_email: "",
				engineer_phone: "",
			},
			noteData:{
				summary:"",
				description:""
			},
			phoneLoaded: "no",
			noteFormId: 'add-note-form',
			checkingCloseConfirmation: false
		}
	},
	mounted() {
		if(this.caseEntry){
			this.updateData();
		}
		if(!this.uniqueId){
			this.uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		}
	},
	methods: {
		findApprovalResponse(){
			if(this.caseEntry.responses){
				for(let i = 0; this.caseEntry.responses.length > i;++i){
					if(this.caseEntry.responses[i].type == "approval"){
						return this.caseEntry.responses[i];
					}
				}
			}
			return null;
		},
		countryChanged(data){
			if(data == null) return;
			if(typeof data === 'string'){
				this.formData.engineer_country_code = data;
			}else{
				// object
				this.formData.engineer_country_code = data.iso2;
			}
		},
		onPhoneChanged(number){
			this.formData.engineer_phone = number;
		},
		// eslint-disable-next-line
		onPhoneValidated(valid){
			this.valid_engineer_number = valid;
		},
		submitResponseUpdate(){
			this.emitter.emit('loader',true);
			this.formData.id = this.caseResponse ? this.caseResponse.id:null;
			//eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}caller/case/${this.caseEntry.token}/update-response`,this.formData)
				.then((pack) => this.updateSuccess(pack))
				.catch((e) => this.updateFailure(e))
		},
		submitNote(){
			//grab content for good measure
			this.noteData.description = this.$refs.descriptionEditor.getContent();
			if(this.noteData.summary.trim() == "" && this.noteData.description.trim() == ""){
				this.emitter.emit('alert',{message: "Please provide some information in the note.", type: "warning"});
				return;
			}
			this.emitter.emit('loader',true);
			//eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}caller/case/${this.caseEntry.token}/note`,this.noteData)
				.then((pack) => this.updateNoteSuccess(pack))
				.catch((e) => this.updateFailure(e))
		},
		updateData(){
			this.caseResponse = this.findApprovalResponse();
			
			if(this.caseResponse){
				this.formData.case_number = this.caseResponse.case_number;
				this.formData.engineer_name = this.caseResponse.engineer_name;
				this.formData.engineer_email = this.caseResponse.engineer_email;
				this.formData.engineer_phone = this.caseResponse.engineer_phone;
				this.phoneLoaded = "yes";
			}
		},
		updateSuccess(pack){
			if(pack.data && pack.data.success){
				this.emitter.emit('loader',false);
				//eslint-disable-next-line
				UIkit.modal(this.$refs.responseUpdateForm).hide();
				this.$emit("updated");
			}
		},
		updateNoteSuccess(pack){
			if(pack.data){
				this.emitter.emit('loader',false);
				this.noteData.summary = "";
				this.noteData.description = "";
				//eslint-disable-next-line
				UIkit.modal(this.$refs.addNoteForm).hide();
				this.$emit("updated");
			}
		},
		updateFailure(e){
			this.emitter.emit('alert',{message: e.error, type: "warning"});
		},
		confirmClose(){
			this.checkingCloseConfirmation = true;
		},
		closeCase(){
			this.checkingCloseConfirmation = false;
			this.emitter.emit('load', true);
			// eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}caller/case/${this.caseEntry.token}/close`,{
				token: this.$route.params.token
			})
				.then(response => this.closeComplete(response.data))
				// eslint-disable-next-line
				.catch(error => this.closeFailure(error));
		},
		cancelClose(){
			this.checkingCloseConfirmation = false;
		},
		closeComplete(){
			this.emitter.emit('load', false);
			this.$router.push({ name: 'dashboard' });
		},
		closeFailure(error){
			console.log(error);
			this.emitter.emit('load', false);
			this.emitter.emit('alert', {
				type: 'warning',
				message: "Something caused the case not to close. Please try again later."
			});
		}
	},
	computed:{
		...mapState({
			user: state => state.user,
			company: state => state.company
		}),
		canUpdateEngineer(){
			if(this.caseEntry.status !== "accepted"){
				return false;
			}
			return (this.buttonType === null || this.buttonType === "engineer") &&
				this.company && this.caseEntry.receiver && this.company.id === this.caseEntry.receiver.id;
		},
		canShowNotes(){
			/*if(this.company && this.company.allow_notes){
				return true;
			}
			return !!((this.caseEntry.company && this.caseEntry.company.allow_notes)
				|| (this.caseEntry.receiver && this.caseEntry.receiver.allow_notes));*/
			//All companies can send notes
			return true;
		},
		canCloseCase(){
			if(this.caseEntry.status !== "accepted"){
				return false;
			}
			return (this.buttonType === null || this.buttonType === "close") &&
			this.company && this.caseEntry.company && this.company.id === this.caseEntry.company.id;
		},
		canAddNote(){
			return (this.buttonType === null || this.buttonType === "note") && this.canShowNotes;
		},
		canShowControls(){
			return (this.caseEntry.status == "accepted" || this.caseEntry.status == "information") && this.canShowNotes;
		},
		caseLabel(){
			return this.caseEntry.case_type == 'manager' ? "Internal Reference (Case/Project/Customer)":"Case #";
		},
		nameLabel(){
			return this.caseEntry.case_type == 'manager' ? "Name":"Engineer Name";
		},
		emailLabel(){
			return this.caseEntry.case_type == 'manager' ? "Email":"Engineer Email";
		},
		phoneLabel(){
			return this.caseEntry.case_type == 'manager' ? "Phone":"Engineer Phone";
		}
	},
	watch: {
		caseEntry(){
			this.updateData();
		}
	},
	emits:["updated"]
}
</script>